// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeArticle from 'components/Contentful/KnowledgeArticle'
import SideNavigation from 'components/SideNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Das Gebäudeprogramm: Fördergelder"
        meta={[
          { name: 'description', content: 'Das Gebäudeprogramm von Bund und Kantonen: Liegenschaft sanieren ✔️ Energieverbrauch senken ✔️ Fördergelder kassieren ✔️ Jetzt informieren ✔️' },
          { name: 'keywords', content: 'gebäudeprogramm' },
        ]}
      >
        
      </Helmet>
      
      <div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "6imdAPCpzzJin32ITPF7Tv",
        "updatedAt": "2020-03-18T15:08:38.076Z",
        "label": "Schweizweite Förderung",
        "page": {
            "slug": "foerdergelder/schweizweite-foerderung"
        }
    },
    {
        "type": "link",
        "id": "4OW4jSV0Vk2H7QMmCcj8gc",
        "updatedAt": "2021-09-03T09:57:55.973Z",
        "label": "Das Gebäudeprogramm",
        "page": {
            "slug": "foerdergelder/das-gebaeudeprogramm"
        }
    },
    {
        "type": "link",
        "id": "cIQKSgk11rayF9abVfi4k",
        "updatedAt": "2020-03-27T08:01:44.514Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "foerdergelder/haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
        "updatedAt": "2020-03-13T15:09:44.207Z",
        "label": "Fördergelder Kanton Aargau",
        "page": {
            "slug": "foerdergelder/kanton-aargau"
        }
    },
    {
        "type": "link",
        "id": "6jyOwojHZQnjIMizqwQnXQ",
        "updatedAt": "2020-03-23T09:16:33.860Z",
        "label": "Fördergelder Kanton Basel-Landschaft",
        "page": {
            "slug": "foerdergelder/kanton-basel-landschaft"
        }
    },
    {
        "type": "link",
        "id": "539aNbJu1biigQT5Unbhd2",
        "updatedAt": "2020-03-23T10:22:43.087Z",
        "label": "Fördergelder Kanton Basel-Stadt",
        "page": {
            "slug": "foerdergelder/kanton-basel-stadt"
        }
    },
    {
        "type": "link",
        "id": "2jA1lO1AcRz7oZ2sSFx9vg",
        "updatedAt": "2020-03-23T10:27:44.702Z",
        "label": "Fördergelder Kanton Bern",
        "page": {
            "slug": "foerdergelder/kanton-bern"
        }
    },
    {
        "type": "link",
        "id": "223GuzpRXRVSaAYk6BFtSq",
        "updatedAt": "2020-03-23T11:00:32.709Z",
        "label": "Fördergelder Kanton Luzern",
        "page": {
            "slug": "foerdergelder/kanton-luzern"
        }
    },
    {
        "type": "link",
        "id": "1t30LGgkuILdIGApksWHvv",
        "updatedAt": "2023-09-28T15:35:50.064Z",
        "label": "Fördergelder Kanton Schaffhausen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-schaffhausen"
        }
    },
    {
        "type": "link",
        "id": "11DxxtCQGVZdWGHeKaLoUq",
        "updatedAt": "2020-03-24T09:20:23.945Z",
        "label": "Fördergelder Kanton Solothurn",
        "page": {
            "slug": "foerdergelder/kanton-solothurn"
        }
    },
    {
        "type": "link",
        "id": "3mbZseYEYjKC7VQPe24HQS",
        "updatedAt": "2023-04-20T15:20:55.004Z",
        "label": "Fördergelder Kanton St. Gallen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-st-gallen"
        }
    },
    {
        "type": "link",
        "id": "5RPB8qgJ9nc7aoIwoFxmul",
        "updatedAt": "2023-04-20T15:21:32.792Z",
        "label": "Fördergelder Kanton Thurgau",
        "page": {
            "slug": "foerdergelder/kanton-thurgau"
        }
    },
    {
        "type": "link",
        "id": "4mUIIT6zOW58vyTDWkKUGx",
        "updatedAt": "2020-03-24T10:00:31.055Z",
        "label": "Fördergelder Kanton Zug",
        "page": {
            "slug": "foerdergelder/kanton-zug"
        }
    },
    {
        "type": "link",
        "id": "59vmXjjI3AnPXmVsC4kKBz",
        "updatedAt": "2020-03-24T10:03:26.817Z",
        "label": "Fördergelder Kanton Zürich",
        "page": {
            "slug": "foerdergelder/kanton-zuerich"
        }
    }
]} /><div
          className='bg__flex--white'
          key='7um2Rv8IZUeQFMOhmIqczb-xhK8X9omD1LH7zsUsOoU7'
          id=''
        >
          <KnowledgeArticle
            {...{
    "type": "knowledgeArticle",
    "id": "xhK8X9omD1LH7zsUsOoU7",
    "updatedAt": "2023-04-20T16:07:25.281Z",
    "title": "Das Gebäudeprogramm von Bund und Kantonen",
    "keyword": "Das Gebäudeprogramm",
    "articleType": "Infoartikel",
    "description": "Bund und Kantone wollen mit dem Gebäudeprogramm den Energieverbrauch im Schweizer Gebäudepark erheblich reduzieren und den CO2-Ausstoss senken.",
    "content": [
        {
            "type": "infoarticle",
            "id": "55ItQEj5FCrWXsG17a9coL",
            "updatedAt": "2020-03-27T08:29:33.516Z",
            "title": "Das Gebäudeprogramm",
            "text": "<h3>Energetisch sanieren bewirkt viel</h3>\n<p>Eine bessere Dämmung kann den Wärmebedarf eines Gebäudes um mehr als die Hälfte reduzieren, ein Umstieg auf erneuerbare Energien beim Heizen die CO2-Emmissionen fast auf null senken. Mit dem Gebäudeprogramm wollen Bund und Kantone den Energieverbrauch und den CO2-Ausstoss des Schweizer Gebäudeparks reduzieren.</p>\n<p>Dank des Gebäudeprogramms erhalten Sie Fördergelder für energetisch wirksame bauliche Massnahmen. Informieren Sie sich jetzt, für welche Massnahmen Sie Förderbeiträge erhalten und wie Sie ein Gesuch stellen können. Beginnen Sie mit einem GEAK Plus, dem Gebäudeenergieausweis der Kantone plus Empfehlungen.</p>\n",
            "gallery": [
                {
                    "type": "youTubeLink",
                    "id": "4cYGEJRTZwlmkvOKt2LebY",
                    "updatedAt": "2020-03-24T11:02:08.417Z",
                    "title": "Das Gebäudeprogramm – Erklärvideo",
                    "link": "https://www.youtube.com/watch?time_continue=322&v=yinV4U9KkU0&feature=emb_logo",
                    "description": "<p>Das Video erklärt, wie Gesuchstellende vorgehen müssen, um Fördergeld aus dem Gebäudeprogramm zu erhalten.</p>\n",
                    "videoId": "yinV4U9KkU0",
                    "start": null
                }
            ]
        }
    ],
    "topic": {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    "previewImage": {
        "description": "Logo farbig Das Gebäudeprogramm von Bund und Kantonen",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4A5QO38evSKGhvepvW9bTy/ecfa6f07d8be64826db1482416fa775b/02---Format---Blogartikel-Einleitung-_-Bild.png"
    },
    "previousArticle": "/foerdergelder/schweizweite-foerderung",
    "nextArticle": "/foerdergelder/haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
